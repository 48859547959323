import { ComponentProps } from 'react';
import Image from 'next/image';
import { LiveConcert, LiveConcertType } from 'generated/graphql';
import PremiereImage from 'public/images/premiere.svg';
import { Badge } from 'src/components/common/badge';
import styles from 'src/components/live-concert/live-concert-badge.module.css';
import RenderClientOnly from 'src/components/render-client-only';
import useLiveConcertState, { LiveConcertStatus } from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';

type LiveConcertBadgeProps = {
  liveConcert: Pick<LiveConcert, 'typeDisplayName' | 'startTime' | 'endTime' | 'streamStartTime' | 'reruns' | 'type'>;
} & Pick<ComponentProps<typeof Badge>, 'small'>;

/**
 * Icon for the LiveConcertBadge that renders either an (animated) live indicator or a "Premiere" icon.
 */
function LiveConcertBadgeIcon({ status, type }: { status: LiveConcertStatus; type: LiveConcertType }) {
  const isLiveNow = status === 'live' || status === 'pre-live';
  const isPremiere = type === LiveConcertType.Premiere;

  return isPremiere ? (
    <Image src={PremiereImage as string} width="12" height="12" alt="" className="ml-1.5 mr-2" />
  ) : (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" className="mr-2">
      <circle
        cx="12"
        cy="12"
        fill={isLiveNow ? '#ce427d' : '#df1142'}
        fillOpacity={isLiveNow ? 1 : 0.2}
        r="12"
        className={isLiveNow ? styles.circle2 : undefined}
      />
      <circle
        cx="12"
        cy="12"
        fill={isLiveNow ? '#d86897' : '#e02f59'}
        fillOpacity={isLiveNow ? 1 : 0.1}
        r="8"
        className={isLiveNow ? styles.circle1 : undefined}
      />
      <circle cx="12" cy="12" fill={isLiveNow ? '#dadada' : '#c2135c'} r="3" />
    </svg>
  );
}

/**
 * A LiveConcertBadge is a badge that indicates the status of a live concert.
 * If a concert is live or pre-live, it will render a "Live Now" badge with an animated indicator,
 * otherwise it will render a badge with the type of the concert (e.g. "Premiere").
 * If a concert is in the past, it will not render a badge.
 */
export function LiveConcertBadge({ liveConcert, small }: LiveConcertBadgeProps) {
  const t = useTranslate();
  const { status } = useLiveConcertState(liveConcert);

  const isLiveNow = status === 'live' || status === 'pre-live';
  const badgeColor: ComponentProps<typeof Badge>['color'] = isLiveNow ? 'live' : 'secondary';
  // If the concert is live or pre-live, show "Live Now", otherwise show the type of the concert (e.g. "Premiere")
  const badgeText =
    status === 'live' || status === 'pre-live' ? t('live_concert__badge_live_now') : liveConcert.typeDisplayName;
  // Don't render a badge if the live concert is in the past and has no scheduled reruns
  if (status === 'past') return null;

  return (
    <RenderClientOnly>
      <Badge icon={<LiveConcertBadgeIcon status={status} type={liveConcert.type} />} color={badgeColor} small={small}>
        {badgeText}
      </Badge>
    </RenderClientOnly>
  );
}
