import { LiveConcert, LiveConcertRerun } from 'generated/graphql';
import { differenceInCalendarDays, differenceInMinutes } from 'src/utilities/date-helpers';
import { formatIcsDate } from 'src/utilities/ics';
import { capitalizeFirstCharacter, IntlLocale } from 'src/utilities/intl-helpers';
import { getAbsoluteUrl, getNodePath } from 'src/utilities/url-helpers';

type UpcomingLiveConcertRerun = Pick<LiveConcertRerun, 'startTime' | 'endTime'>;
type UpcomingLiveConcertDatesInput = Pick<LiveConcert, 'startTime' | 'endTime'> & {
  reruns: UpcomingLiveConcertRerun[];
};
type UpcomingLiveConcertDatesPayload = { startTime: string; endTime: string }[];

/**
 * Takes a live concert node and returns an array of concert dates in the future.
 */
export function getUpcomingLiveConcertDates(
  liveConcert: UpcomingLiveConcertDatesInput,
): UpcomingLiveConcertDatesPayload {
  const { startTime, endTime, reruns } = liveConcert;
  return (
    [
      { startTime, endTime },
      ...reruns.map((rerun) => ({
        startTime: rerun.startTime,
        endTime: rerun.endTime,
      })),
    ]
      // check if stream or rerun is in the future
      .filter(({ startTime }) => new Date(startTime) > new Date())
  );
}

/**
 * Generate and download an ICS file for a Live Concert
 * @see https://www.rfc-editor.org/rfc/rfc5545.html
 */
export function generateLiveConcertIcal(
  liveConcert: Pick<LiveConcert, '__typename' | 'id' | 'title' | 'shortDescription' | 'startTime' | 'endTime'>,
): void {
  const { id, title, shortDescription, startTime, endTime } = liveConcert;
  const url = getAbsoluteUrl(getNodePath(liveConcert));
  // description is the short description (if available) plus the url
  const description = [shortDescription, url].filter(Boolean).join('\n\n');

  const icsLines = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:STAGE+',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    'BEGIN:VEVENT',
    `UID:${id}-${new Date(startTime).getTime()}`,
    `DTSTAMP:${formatIcsDate(new Date(Date.now()))}`,
    `DTSTART:${formatIcsDate(new Date(startTime))}`,
    `DTEND:${formatIcsDate(new Date(endTime))}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `LOCATION:${url}`,
    `URL:${url}`,
    'BEGIN:VALARM',
    'ACTION:DISPLAY',
    'DESCRIPTION:REMINDER',
    'TRIGGER:-PT15M',
    'END:VALARM',
    'END:VEVENT',
    'END:VCALENDAR',
  ];
  const dataUrl = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsLines.join('\r\n'));

  try {
    const save = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
    save.rel = 'noopener';
    save.href = dataUrl;
    save.target = '_blank';
    save.download = `${id}.ics`;
    const event = new MouseEvent('click', {
      view: window,
      button: 0,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(event);
    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Takes a date and returns a formatted string representing the time until the live concert starts.
 * Depending on the time difference, the format will be different.
 * - If the concert starts in less than 60 minutes, the format will be "In X min".
 * - If the concert starts today or tomorrow, the format will be "Today, HH:MM" or "Tomorrow, HH:MM".
 * - If the concert starts within the next 7 days, the format will be "Weekday, HH:MM".
 * - If the concert starts later, the format will be "DD/MM/YYYY, HH:MM".
 * @param date Date of the live concert
 * @param locale Locale to use for formatting
 * @returns Formatted string representing the time until the live concert starts
 */
export function liveConcertTimeFormat(date: Date, locale: IntlLocale): string | undefined {
  const now = new Date();
  const diffInMinutes = differenceInMinutes(date, now);
  const diffInCalendarDays = differenceInCalendarDays(date, now);
  const relativeTimeFormat = new Intl.RelativeTimeFormat(locale, { style: 'short', numeric: 'auto' });
  const isJapanese = locale.startsWith('ja');

  if (now > date || diffInMinutes <= 0) {
    return undefined;
  }

  if (diffInMinutes < 60) {
    // If the concert starts in less than 60 minutes, return the time in minutes (e.g. "In 5 min")
    return capitalizeFirstCharacter(relativeTimeFormat.format(diffInMinutes, 'minute'));
  } else if (diffInCalendarDays <= 6) {
    // If the concert starts within the next 7 days, return the weekday and time (e.g. "Monday, 20:15")
    const dateParts = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }).formatToParts(date);
    // If date is today or tomorrow, replace the weekday with "Today" or "Tomorrow" (e.g. "Today, 20:15")
    if (diffInCalendarDays <= 1) {
      const value = capitalizeFirstCharacter(relativeTimeFormat.format(diffInCalendarDays, 'day'));
      const weekdayIndex = dateParts.findIndex((part) => part.type === 'weekday');
      dateParts[weekdayIndex].value = value;
    }
    // Extract the hour and minute from the date parts, including the literal (usually a ':') between them
    const timeParts = dateParts.slice(
      dateParts.findIndex((part) => part.type === 'hour'),
      dateParts.findIndex((part) => part.type === 'minute') + 1,
    );
    const weekDayPart = dateParts.find((part) => part.type === 'weekday');
    return [weekDayPart?.value, timeParts.map((part) => part.value).join('')].join(isJapanese ? ' ' : ', ');
  } else {
    // If the concert starts later, return the date and time (e.g. "Mon 14/02, 20:15")
    const dateParts = new Intl.DateTimeFormat(locale, {
      weekday: isJapanese ? 'long' : 'short',
      month: isJapanese ? 'short' : '2-digit',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).formatToParts(date);
    // Find any `type: 'literal'` date parts between weekday and month or day parts
    // e.g. For `Mon, 14/02, 20:15`, that would be the `, ` part

    // Find the literal part between the weekday and day/month (e.g the first `, ` in `Mon, 14/02, 20:15`)
    const weekdayIndex = dateParts.findIndex((part) => part.type === 'weekday');
    const dayOrMonthIndex = Math.min(
      dateParts.findIndex((part) => part.type === 'day'),
      dateParts.findIndex((part) => part.type === 'month'),
    );
    const literalIndex = dateParts.findIndex(
      (part, index) => index > weekdayIndex && index < dayOrMonthIndex && part.type === 'literal',
    );

    // Remove commas from the literal part if it exists
    if (literalIndex !== -1) {
      dateParts[literalIndex].value = dateParts[literalIndex].value.replace(',', '');
    }

    // Join the date parts to get the formatted date (e.g. "Mon 14/02, 20:15")
    return dateParts.map((part) => part.value).join('');
  }
}
