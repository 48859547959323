import { PropsWithChildren } from 'react';
import clsx from 'clsx';

type BadgeProps = PropsWithChildren<{
  color?: 'primary' | 'secondary' | 'live';
  small?: boolean;
  icon?: JSX.Element;
}>;

/**
 * A Badge is used to display a small amount of information about a video element.
 * It comes in two sizes and three colors.
 *
 * @example
 * ```tsx
 * <Badge color="live">Live</Badge> // Live badge
 * <Badge color="secondary" small>DOCUMENTARY</Badge> // a small video Documentary badge
 * ```
 */
export function Badge({ children, color = 'primary', icon, small = false }: BadgeProps) {
  return (
    <span
      className={clsx(
        'relative inline-flex w-fit select-none items-center rounded-full',
        small ? 'dg-text-medium-5 h-7' : 'dg-text-medium-8 h-10',
        icon ? (small ? 'pl-0.5 pr-3' : 'pl-2 pr-3') : 'px-4',
        color === 'primary' && 'bg-white/70 text-mainBgBlueC2 shadow-[0_2px_10px_rgba(0,0,0,0.4)] backdrop-blur-sm',
        color === 'secondary' &&
          "bg-surface-600 text-surface-0 before:absolute before:inset-0 before:rounded-full before:border before:border-solid before:border-transparent before:content-[''] before:[background:linear-gradient(95.91deg,#414856_0%,#2F3747_20%,#232C3D_90%,#424A58_100%)_border-box] before:![mask-composite:exclude] before:[mask:linear-gradient(#fff_0_0)_padding-box,linear-gradient(#fff_0_0)]",
        color === 'live' && 'bg-support-live text-surface-0',
      )}
      data-test="badge"
    >
      {icon}
      {children}
    </span>
  );
}
